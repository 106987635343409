<template>
  <div id="MyPageDetail">
    <div class="mypage_header">
      <div class="banner_img_box" :style="[memberSimpleInfo.bannerImageUrl ? {backgroundImage:`url(${memberSimpleInfo.bannerImageUrl})`} : {}]">
        <div class="mypage-info pc">
          <MyPageDetailInfo
            :isArtist="isArtist"
            :artistInfo="artistInfo"
            :showCollection="true"
          />
        </div>
      </div>
      <div class="mypage-info mobile">
        <MyPageDetailInfo
          :isArtist="isArtist"
          :artistInfo="artistInfo"
          :showCollection="true"
        />
      </div>
    </div>
    <div class="mypage_content">

      <div class="mypage_tabs" :class="{is_mobile: isMobile, is_artist: isArtist}">
        <b-tabs class="custom_tabs" v-model="selectedTab">
          <!-- (Mobile Tab 0) Profile -->
<!--          <b-tab v-if="windowWidth < 1200">-->
<!--            <template #title>-->
<!--              {{ $t("mypage.profile") }}-->
<!--            </template>-->
<!--            <MyPageDetailInfoMobile-->
<!--              :isArtist="isArtist"-->
<!--              :artistInfo="artistInfo"-->
<!--              :showCollection="true"-->
<!--            />-->
<!--          </b-tab>-->
          <!-- (Tab 0) Created -->
          <b-tab v-if="isArtist" lazy>
            <template #title>
              CREATED
            </template>
            <NFTs ref="nfts" :idxArtist="memberSimpleInfo.idxArtist" />
          </b-tab>
          <!-- (Tab 1) Collected -->
          <b-tab lazy>
            <template #title>
              {{ $t("mypage.collected") }}
            </template>
            <CollectionsCollection :idxMember="idxMember" />
          </b-tab>
          <!-- (Tab 2) Favorited -->
          <b-tab lazy>
            <template #title>
              {{ $t("mypage.favorited") }}
            </template>
            <FavoritedCollections :idxMember="Number(idxMember)" />
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <MyPageContractSelectModal ref="myPageContractSelectModal" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MyPageDetailInfo from './mypage/MyPageDetailInfo.vue';
import NFTs from './mypage/NFTs.vue';
import MyPageDetailInfoMobile from './mypage/MyPageDetailInfoMobile.vue';
import MyPageContractSelectModal from './mypage/MyPageContractSelectModal.vue';
import CollectionsCollection from './components/collections/CollectionsCollection.vue';
import FavoritedCollections from './components/common/FavoritedCollections.vue';

export default {
  name: "Collections",
  components: {
    MyPageDetailInfo,
    NFTs,
    MyPageDetailInfoMobile,
    MyPageContractSelectModal,
    CollectionsCollection,
    FavoritedCollections,
  },
  props: {
    idxEncode: {
      type: String,
      default: ""
    },
    updateComplete: {
      type: Boolean,
      default: false      
    }
  },
  data() {
    return {
      windowWidth: 1920,
      selectedTab: 0,
      copiedEthAddr: false,
      copiedKlayAddr: false,
      externalParams: {
        keywordProductName: "",
      },
      sortSelected: {},
      sortOptions: [
        {
          value: { id: "orderDate", desc: "desc" },
          text: this.$t("product.sortByRecentlySold"),
        },
        {
          value: { id: "amt", desc: "desc" },
          text: this.$t("product.sortByHighestPrice"),
        },
        {
          value: { id: "amt", desc: "asc" },
          text: this.$t("product.sortByLowestPrice"),
        },
      ],
      visibleSortBy: false,
      artistInfo: {}
    };
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
      this.requestMemberInfo();
      if (this.$route.params.updateComplete) {
        this.$store.commit("setCommonAlertModalInfo", {
          msgHtml: this.$t("mypage.updatedProfile"),
          show: true,
          fontSize: "sm"
        })
      }
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  directives: {
    "click-outside": {
      bind: function(el, binding, vnode) {
        // create event
        el.eventClickOutside = function(event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.arg](binding.value);
          }
        };
        // event binding
        document.body.addEventListener("click", el.eventClickOutside);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.eventClickOutside);
      }
    }
  },
  computed: {
    ...mapState("profile", ["memberSimpleInfo"]),
    ...mapState(["wallet"]),
    idxMember() {
      return window.atob(this.idxEncode);
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    isArtist() {
      if (this.memberSimpleInfo.roles != undefined) {
        let artistIndex = this.memberSimpleInfo.roles.findIndex((el) => {
          return el.code === "ROLE_ARTIST"
        })
        if (artistIndex === -1) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
  },
  methods: {
    ...mapActions("profile", ["getProfileSimple"]),
    ...mapActions("gallery", ["getGalleryArtist"]),
    handleResize(event) {
      this.windowWidth = window.innerWidth;
    },
    requestMemberInfo() {
      if (this.idxMember !== undefined) {
        this.getProfileSimple(this.idxMember)
          .then(() => {
            if (this.isArtist) {
              this.requestArtistInfo();
            }
          })
          .catch(response => {
            this.$log.error(response);
            this.$router.push("/error/500");
          });
      }
    },
    requestArtistInfo() {
      if (this.isArtist) {
        this.getGalleryArtist(this.memberSimpleInfo.idxArtist).then(data => {
          if (
            Object.keys(data).length !== 0 &&
            JSON.stringify(data) !== JSON.stringify({})
          ) {
            this.artistInfo = data;
            this.selectedTab = 0;
          }
        });
      }
    },
    showSelectContractModal() {
      this.$refs["myPageContractSelectModal"].show();
    },
    showSortByFilter(value) {
      if (typeof value === "boolean") this.visibleSortBy = value;
      else {
        this.visibleSortBy = !this.visibleSortBy;
      }
    },
    onCopyEthAddr() {
      this.copiedEthAddr = true;
      setTimeout(() => {
        this.copiedEthAddr = false;
      }, 3000);
    },
    onCopyKlayAddr() {
      this.copiedKlayAddr = true;
      setTimeout(() => {
        this.copiedKlayAddr = false;
      }, 2000);
    },
    replaceNewLine(str) {
      if (str !== undefined && str !== null && str !== "") {
        return str.replace(/(?:\r\n|\r|\n)/g, "<br />");
      } else {
        return "";
      }
    },
  }
};
</script>
